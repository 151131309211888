<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">SEND MEMO</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5 mb-5">
          <v-layout row wrap>
            <v-flex xs12 md3>
              <v-layout row wrap class="mx-2 mt-2">
                <v-flex xs12 md12>
                  <v-select
                    v-model="selection"
                    class="mx-2"
                    dense
                    label="Selection"
                    :items="['Active','Temporary Deactivated','Permanent Deactivated']"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="category_id"
                    class="mx-2 mt-4"
                    dense
                    label="Category"
                    :items="category_items"
                    item-text="category"
                    item-value="id"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="search_by"
                    class="mx-2 mt-4"
                    dense
                    label="Search By"
                    :items="['Name','Branch','Account #']"
                    @change="selected"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12 v-if="search_by!=''">
                  <v-autocomplete
                    class="mx-2 mt-4"
                    dense
                    outlined
                    v-model="search"
                    :loading="isLoading"
                    @keyup.enter="searching($event.target.value)"
                    label="Search"
                    hide-no-data
                    placeholder="Press Enter to Search "
                    :prepend-icon="icons.mdiAccountSearchOutline"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 v-if="search_items.length>0">
                  <v-data-table dense
                                :headers="headers"
                                height="600"
                                :items="search_items"
                                disable-pagination
                                hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        @click="get_search_items_info(item)"
                        :class="{'info white--text': item.id===selectedDepositId}"
                      >
                        <td>
                          {{ item.name
                          }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md3>
              <v-layout row wrap class="mx-2 mt-2">
                <v-flex xs12 md12>
                  <v-data-table dense
                                :headers="headers"
                                :items="search_items2"
                                disable-pagination
                                hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        :class="{'info white--text': item.id===selectedDepositId2}"
                      >
                        <td>
                          {{ item.name
                          }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md6>
              <v-layout row wrap class="mx-2 mt-2">

                <v-flex xs12 md12>
                  <v-text-field
                    class="mx-2"
                    v-model="date_released"
                    dense
                    label="Date Release"
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    type="date"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    class="mx-2"
                    v-model="subject"
                    dense
                    label="Subject"
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                  ></v-text-field>
                  <v-textarea
                    class="mx-2"
                    v-model="grounds"
                    dense
                    label="Grounds"
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-textarea>
                </v-flex>
                <v-row>
                  <!-- alert -->
                  <v-col cols="12" v-show="alert">
                    <v-alert
                      color="warning"
                      text
                      class="mb-0"
                    >
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{alert_message}}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn
                      class="w-full"
                      color="primary"
                      @click="done_request"
                      v-if="!saving_request"
                    >
                      Done
                    </v-btn>
                    <v-progress-circular
                      :size=50
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>
<script>

  import {
    mdiAlertOutline
    , mdiAccountSearchOutline
  } from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  const initialState = () => {
    return {
      search_by: '',
      selection: '',
      category_id: '',
      category_items: [],
      selectedDepositId: -1,
      selectedDepositId2: -1,

      search: '',
      search_items: [],
      search_items2: [],
      isLoading: false,

      saving_request: false,
      alert: false,
      is_allowance_only: false,
      alert_message: '',
      subject: '',
      grounds: '',
      date_released: '',

      member_information: {},
      headers: [
        {text: 'Details', value: 'name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['search_all_by_status_employee',]),
      ...mapActions('employee_memo_history', ['bulk_register_memo_employee']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async searching(value) {
        this.name = ''
        this.branch = ''

        this.isLoading = true
        await this.search_all_by_status_employee({
          search_word: value,
          category_id: this.category_id,
          status: this.selection,
          search_by: this.search_by,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      selected() {
        this.selectedDepositId = -1
        this.search = ''
        this.search_items = []
        this.isLoading = false
        this.member_information = {category: '', branch: '', employee_position: ''}
        this.compensation_histories = []
        this.memo_history = []
        this.change_history = []
      },
      get_search_items_info(value) {
        this.selectedDepositId = value.id
        this.member_information = value.details
        if (!this.itemExists(value)) {
          this.search_items2.push(value)
        } else {
          this.change_snackbar({
            show: true,
            color: 'error',
            text: 'ALREADY SELECTED',
          })
        }
      },
      itemExists(value) {
        return this.search_items2.includes(value)
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.search_items2.length === 0) {
          this.alert = true
          this.alert_message = 'Select Employee First'
          this.saving_request = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('employees', JSON.stringify(this.search_items2));
            data.append('date_of_release', this.date_released);
            data.append('subject', this.subject.toUpperCase());
            data.append('grounds', this.grounds.toUpperCase());
            data.append('employee_id_released', this.employee_id);
            this.bulk_register_memo_employee(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                Object.assign(this.$data, initialState())
                this.initialize_data()
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving_request = false
          }
        }
      },
    }
  }
</script>
